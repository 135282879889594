import React from 'react';
import '../styles/App.css';

function BlocUn() {

    return (
        <div className="blocUn">
            <div className=" text-center title"> Concepteur Développeur d'Applications</div>
            <div className=" text-center title-bis">Mais pas que ... </div>
            <br/>
            <div className="row m-0">
                <div className="col-4 dev"> Conception </div>
                <div className="col-4 dev"> Front-End </div>
                <div className="col-4 dev"> Back-End </div>
            </div>
        </div>
    )
}
export default BlocUn;