import React from 'react';
import '../styles/App.css';

function BlocTrois() {

    return (
        <div className="blocTrois">
            .parcours()
            <br/>
            ....
        </div>
    )
}
export default BlocTrois;