import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import'bootstrap-css-only/css/bootstrap.min.css';
import'mdbreact/dist/css/mdb.css';
// import { useEffect, useState } from 'react';
import './styles/App.css';
import Header from "./components/Header";
import BlocUn from "./components/blocUn";
import BlocDeux from "./components/blocDeux";
import BlocTrois from "./components/blocTrois";


function App() {

  // const [date, setDate] = useState(null);
  // useEffect(() => {
  //   async function getDate() {
  //     const res = await fetch('/api/date');
  //     const newDate = await res.text();
  //     setDate(newDate);
  //   }
  //   getDate();
  // }, []);


  return (
      <>
        <Header/>
        <div className="orga">
              <BlocUn/>
              <BlocDeux/>
              <BlocTrois/>
        </div>
      </>
  );
}

export default App;
