import React from 'react';
import '../styles/App.css';

function BlocDeux() {

    return (
        <div className="blocDeux">
            .moi()
            <br/>
            ....
        </div>
    )
}
export default BlocDeux;